import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addEmployees,
  updateEmployees,
  getEmployee,
} from "../../actions/userActions/employeeAction";
import { getCities } from "../../actions/userActions/cityAction";
import { getDesignations } from "../../actions/userActions/designationAction";
import { getOfficeLocations } from "../../actions/userActions/officelocationAction";
import { getDepartments } from "../../actions/userActions/departmentAction";
import { getTeams } from "../../actions/userActions/teamAction";
import { getEmploymentStatuses } from "../../actions/userActions/employmentStatusAction";
import { getRoles } from "../../actions/userActions/roleAction";
import {
  getEmployees,
  getGenders,
} from "../../actions/userActions/employeeAction";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import moment from 'moment';
import { SaveButton } from "../subComponents/buttons/SaveButton";
import { CancelButton } from "../subComponents/buttons/CancelButton";
import { CircularProgress } from "@mui/material";
import HrComponent from "../reuse_components/HrComponent";
import { SIZE_OF_PAGE } from "../../actions/types";
import { getvisitProfile } from "../../actions/userActions/VisitProfileAction";

function AddEmployees() {
  const [retryKey, setRetryKey] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [tryagain, setTryagain] = useState(false);
  const [name, setName] = useState("");
  const [goBack, setGoBack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  const currentDate = new Date();
  const eighteenYearsAgo = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const twentyFourYearsAgo = new Date(
    currentDate.getFullYear() - 24,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const [page, setPage] = useState(1);


  const cityBody = {
    filter: {
      textSearch: "",
      countryId: "1"
    },
    pageNumber: -1,
    pageSize: 0,
  };
  const designationBody = {
    filter: {
      textSearch: ""
    },
    pageNumber: -1,
    pageSize: 0,
  };
  const visitLocationbody = {
    filter: {
      textSearch: ""
    },
    pageNumber: -1,
    pageSize: 0
  }
  const locationBody = {
    filter: {
      textSearch: "",
      cityName: "",
    },
    pageNumber: -1,
    pageSize: 0,
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [birthDate, setBirthDate] = useState(twentyFourYearsAgo);
  const initialValues = {
    firstName: "",
    lastName: "",
    gender: "",
    dateOfJoining: "",
    dateOfBirth: twentyFourYearsAgo,
    nic: "",
    passport: "",
    address: "",
    city: "",
    personalContact1: "",
    personalContact2: "",
    personalEmail: "",
    designation: "",
    jobLocation: "",
    officialEmail: "",
    officialContact1: "",
    officialContact2: "",
    department: "",
    reportsTo: "",
    team: "",
    employmentStatus: "",
    password: "",
    employeeRoles: [],
    status: false,
    empId: "",
    visitProfileId: null
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [inactiveLabels, setInactiveLabels] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const pageNumber = -1;
  useEffect(() => {
    if (location.id) {
      dispatch(getEmployee(location.id));
    }

    const fetchData = async () => {
      try {
        dispatch(getCities(cityBody));
        await Promise.all([
          dispatch(getDesignations(designationBody)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getOfficeLocations(locationBody)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getDepartments(pageNumber)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getTeams(pageNumber, SIZE_OF_PAGE, query)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getEmploymentStatuses(pageNumber)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getRoles(pageNumber)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getEmployees(pageNumber)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getGenders()).catch(error => {
            return Promise.reject();
          }),

          dispatch(getvisitProfile(pageNumber, SIZE_OF_PAGE, query)).catch(error => {
            return Promise.reject();
          }),

          setLoadingData(false)
        ]).then((response) => {

        }).catch((error) => {
          throw error;
        });
        setLoadingData(false);
      } catch (error) {
        setTryagain(true);
        setLoadingData(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, location.id, retryKey]);


  const employee = useSelector((state) => state.employees_reducer.employee);
  const visitProfile = useSelector((state) => state.visitProfile_reducer.visitProfile);
  const initialSelectedValues = {
    empId:
      employee && employee !== "isLoading" && location.id && employee.empId
        ? { label: "loading...", value: "" }
        : "",
    firstName:
      employee && employee !== "isLoading" && location.id && employee.firstName
        ? { label: "loading...", value: "" }
        : "",
    lastName:
      employee && employee !== "isLoading" && location.id && employee.lastName
        ? { label: "loading...", value: "" }
        : "",
    cnic:
      employee && employee !== "isLoading" && location.id && employee.cnic
        ? { label: "loading...", value: "" }
        : "",
    gender:
      employee && employee !== "isLoading" && location.id && employee.gender
        ? { label: "loading...", value: "" }
        : "",
    city:
      employee && employee !== "isLoading" && location.id && employee.city
        ? { label: "loading...", value: "" }
        : "",
    designation:
      employee && employee !== "isLoading" && location.id && employee.designation
        ? { label: "loading...", value: "" }
        : "",
    visitProfileId:
      employee && employee !== "isLoading" && location.id && employee.visitProfileId
        ? { label: "loading...", value: "" }
        : "",
    jobLocation:
      employee &&
        employee !== "isLoading" &&
        location.id &&
        employee.jobLocation
        ? { label: "loading...", value: "" }
        : "",
    department:
      employee && employee !== "isLoading" && location.id && employee.department
        ? { label: "loading...", value: "" }
        : "",
    reportsTo:
      employee && employee !== "isLoading" && location.id && employee.reportsTo
        ? { label: "loading...", value: "" }
        : "",
    team:
      employee && employee !== "isLoading" && location.id && employee.team
        ? { label: "loading...", value: "" }
        : "",
    employmentStatus:
      employee &&
        employee !== "isLoading" &&
        location.id &&
        employee.employmentStatus
        ? { label: "loading...", value: "" }
        : "",
    employeeRoles:
      employee &&
        employee !== "isLoading" &&
        location.id &&
        employee.employeeRoles
        ? { label: "loading...", value: "" }
        : "",
  };
  const [selected, setSelected] = useState(initialSelectedValues);
  const addEmployeeHandle = async (e) => {
    e.preventDefault();
    const errors = validate(formValues, departments, designationData, teamsData, employmentStatusData, roles);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {

      const updatedFormValues = {
        ...formValues,
        personalEmail: formValues.personalEmail.trim().toLowerCase(),
        officialEmail: formValues.officialEmail.trim().toLowerCase(),
        firstName: formValues.firstName.trim(),
        lastName: formValues.lastName.trim(),
        empId: formValues.empId.trim(),
        address: formValues.address.trim(),
        personalContact1: formValues.personalContact1.trim(),
        personalContact2: formValues.personalContact2.trim(),
        passport: formValues.passport.trim(),
      };
      if (location.id) {
        dispatch(updateEmployees(updatedFormValues));
        setGoBack(true);
      } else {
        dispatch(addEmployees(updatedFormValues));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;

    if (name === "personalEmail") {
      setFormValues({ ...formValues, personalEmail: value, officialEmail: value });
    } else if (name === "personalContact1") {
      setFormValues({ ...formValues, personalContact1: value, officialContact1: value });
    } else if (name === "status") {
      setFormValues({ ...formValues, [name]: value, status: !formValues.status });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };



  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelected(prevValues => ({
      ...prevValues,
      dateOfJoining: formattedDate,
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      dateOfJoining: formattedDate,
    }));
  };


  const findInactiveEntry = (id, data) => {
    if (!Array.isArray(data)) {
      return null;
    }
    const entry = data.find(item => item.id === id);
    return entry && !entry.active ? entry : null;
  };

  const validate = (values, departments, designationData, teamsData, employmentStatusData, roles) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = <p className="text-red">First Name is required!</p>;
    }
    if (!values.lastName) {
      errors.lastName = <p className="text-red">Last Name is required! </p>;
    }

    if (!values.address) {
      errors.address = <p className="text-red">Address is required!</p>;
    }
    if (values.nic.trim().length > 20) {
      errors.nic = (
        <p className="text-red">Maximum number of characters exceeded. Only 20 allowed!</p>
      );
    }
    if (!values.personalContact1) {
      errors.personalContact1 = (
        <p className="text-red">Contact is required!</p>
      );
    } else if (values.personalContact1.length !== 11) {
      errors.personalContact1 = <p className="text-red">Contact is Invalid!</p>;
    }
    if (values.personalContact2 && values.personalContact2.length !== 11) {
      errors.personalContact2 = <p className="text-red">Contact is Invalid!</p>;
    }
    if (!values.personalEmail) {
      errors.personalEmail = <p className="text-red">Email is required!</p>;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.personalEmail)
    ) {
      errors.personalEmail = (
        <p className="text-red">Please enter valid email!</p>
      );
    }

    if (!values.officialEmail) {
      errors.officialEmail = <p className="text-red">Email is required!</p>;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.officialEmail)
    ) {
      errors.officialEmail = (
        <p className="text-red">Please enter valid email!</p>
      );
    }
    if (values.gender === "") {
      errors.gender = <p className="text-red">Gender is required!</p>;
    }

    if (!values.department) {
      errors.department = <p className="text-red">Department is required!</p>;
    }

    if (values.team === "") {
      errors.team = <p className="text-red">Team is required!</p>;
    }
    if (values.employmentStatus === "") {
      errors.employmentStatus = (
        <p className="text-red">Employment Status is required!</p>
      );
    }
    if (values.city === "") {
      errors.city = <p className="text-red">City is required!</p>;
    }

    if (!values.employeeRoles || values.employeeRoles.length === 0) {
      errors.employeeRoles = <p className="text-red">Employee Role is required!</p>;
    }

    if (!values.designation) {
      errors.designation = <p className="text-red">Designation is required!</p>;
    }
    if (!values.reportsTo) {
      errors.reportsTo = <p className="text-red">Report to is required!</p>;
    }
    if (values.jobLocation.length === 0) {
      errors.jobLocationName = (
        <p className="text-red">Job location is required!</p>
      );
    }
    //  EmpId validation
    if (values.empId && values.empId.length > 15) {
      errors.empId = <p className="text-red">Employee id must not exceed 15 characters!</p>;
    }
    if (!values.dateOfJoining) {
      errors.dateOfJoining = (
        <p className="text-red">Date of Joining is required!</p>
      );
    }
    if (!values.jobLocation) {
      errors.jobLocation = (
        <p className="text-red">Job Location is required!</p>
      );
    }
    return errors;
  };

  const cities = useSelector((state) => state.cities_reducer.cities);
  const designations = useSelector(
    (state) => state.designations_reducer.designations
  );
  const designationData = designations && designations[0] && designations?.result?.[0]?.designations;
  const officeLocations = useSelector(
    (state) => state.officeLocations_reducer.officeLocations
  );
  const departments = useSelector(
    (state) => state.departments_reducer.departments
  );
  const departmentsData = departments.result;
  const teams = useSelector((state) => state.teams_reducer.teams);

  const teamsData = teams && teams.result;


  const employmentStatuses = useSelector(
    (state) => state.employmentStatuses_reducer.employmentStatuses
  );

  const employmentStatusData = employmentStatuses.result;
  const roles = useSelector((state) => state.roles_reducer.roles);
  const rolesResultData = roles && roles.result;
  const employees = useSelector((state) => state.employees_reducer.employees);

  const genders = useSelector((state) => state.employees_reducer.genders);
  const addEmployee = useSelector(
    (state) => state.employees_reducer.addEmployee
  );
  const updateEmployee = useSelector(
    (state) => state.employees_reducer.updateEmployee
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));



  const getOptions = (data, name) => {
    const filteredData = Array.isArray(data) && data !== "isLoading" && data.length > 0
      ? data.map((row, i) => ({
        value: row.id,
        label: row.name
          ? row.name
          : row.firstName && row.lastName
            ? `${row.firstName} ${row.lastName}-${row.designationName}`
            : row.address,
        name: name,
      }))
      : [];

    return filteredData;
  };


  useEffect(() => {
    if (!loadingData && location.id) {

      employee &&
        employee !== "isLoading" &&
        setSelected({
          ...selected,
          ["gender"]: getOptions(genders, "gender")[
            getOptions(genders, "gender")
              .map((e) => e.value)
              .indexOf(employee.gender)
          ],
          ["city"]: getOptions(cities && cities !== "isLoading" && cities.length !== 0 && cities[0].cities, "city")[
            getOptions(cities && cities !== "isLoading" && cities.length !== 0 && cities[0].cities, "city")
              .map((e) => e.value)
              .indexOf(employee.city)
          ],
          ["designation"]: getOptions(designations && designations !== "isLoading" && designations.length !== 0 && designations?.result?.[0]?.designations, "designation")[
            getOptions(designations && designations !== "isLoading" && designations.length !== 0 && designations?.result?.[0]?.designations, "designation")
              .map((e) => e.value)
              .indexOf(employee.designation)
          ],

          ["jobLocation"]: getOptions(
            officeLocations && officeLocations !== "isLoading" && officeLocations.length !== 0 && officeLocations[0].officeLocations,
            "jobLocation"
          )[
            getOptions(officeLocations.officeLocations, "jobLocation")
              .map((e) => e.value)
              .indexOf(employee.jobLocation)
          ],
          ["department"]: getOptions(departments && departments !== "isLoading" && departments.length !== 0 && departments.result, "department")[
            getOptions(departments && departments !== "isLoading" && departments.length !== 0 && departments.result, "department")
              .map((e) => e.value)
              .indexOf(employee.department)
          ],
          ["team"]: getOptions(teamsData, "team")[
            getOptions(teamsData, "team")
              .map((e) => e.value)
              .indexOf(employee.team)
          ],

          ["reportsTo"]: getOptions(employees.result, "reportsTo")[
            getOptions(employees.result, "reportsTo")
              .map((e) => e.value)
              .indexOf(employee.reportsTo)
          ],
          ["employmentStatus"]: getOptions(employmentStatuses.result, "employmentStatus")[
            getOptions(employmentStatuses.result, "employmentStatus")
              .map((e) => e.value)
              .indexOf(employee.employmentStatus)
          ],
          ["employeeRoles"]: getOptions(roles && Array.isArray(rolesResultData) && rolesResultData, "employeeRoles")[
            getOptions(roles, "employeeRoles")
              .map((e) => e.value)
              .indexOf(
                employee.employeeRoles[0] && employee.employeeRoles[0].roleId
              )
          ],

        });
      {
        employee &&
          employee !== "isLoading" &&
          setFormValues({
            ...formValues,
            ["firstName"]: employee.firstName,
            ["lastName"]: employee.lastName,
            ["gender"]: employee.gender,
            ["dateOfJoining"]: employee.dateOfJoining,
            ["dateOfBirth"]: employee.dateOfBirth,
            ["nic"]: employee.nic,
            ["passport"]: employee.passport,
            ["address"]: employee.address,
            ["city"]: employee.city,
            ["personalContact1"]: employee.personalContact1,
            ["personalContact2"]: employee.personalContact2,
            ["personalEmail"]: employee.personalEmail,
            ["designation"]: employee.designation,
            ["visitProfileId"]: employee.visitProfileId,
            ["jobLocation"]: employee.jobLocation,
            ["officialEmail"]: employee.officialEmail,
            ["officialContact1"]: employee.officialContact1,
            ["officialContact2"]: employee.officialContact2,
            ["department"]: employee.department,
            ["reportsTo"]: employee.reportsTo,
            ["team"]: employee.team,
            ["employmentStatus"]: employee.employmentStatus,
            ["password"]: employee.password,
            ["employeeRoles"]: employee.employeeRoles,
            ["id"]: location.id,
            ["empId"]: employee.empId,
            ["status"]: employee.status,
            ["dateOfJoining"]: employee.dateOfJoining ? moment(employee.dateOfJoining).format('YYYY-MM-DD') : ""
          });
      }
      {
        employee &&
          employee !== "isLoading" &&
          setSelected(prevValues => ({
            ...prevValues,
            dateOfJoining: moment(employee.dateOfJoining).format('MM-DD-YYYY')
          }));
      }
      {
        employee &&
          employee !== "isLoading" &&
          setBirthDate(new Date(employee.dateOfBirth));
      }
    }
    if (!loadingData && location.id) {
      setName(employee.firstName + " " + employee.lastName);
      const inactiveLabels = {};
      if (departmentsData && departmentsData.length !== 0 && departmentsData != 'isLoading') {
        const inactiveDepartment = findInactiveEntry(formValues.department, departmentsData);
        if (inactiveDepartment) {
          inactiveLabels.department = (
            <p className="text-red">
              {`${inactiveDepartment.name} is inactive!`}
            </p>
          );
        }
      }
      if (teams && Array.isArray(teamsData) && teamsData !== 'isLoading' && teamsData.length !== 0 && teamsData) {
        const inactiveTeam = findInactiveEntry(formValues.team, teamsData);
        if (inactiveTeam) {
          inactiveLabels.team = (
            <p className="text-red">
              {`${inactiveTeam.name} is inactive!`}
            </p>
          );
        }
      }
      if (employmentStatusData && employmentStatusData.length !== 0 && employmentStatusData != 'isLoading') {
        const inactiveEmploymentStatus = findInactiveEntry(formValues.employmentStatus, employmentStatusData);
        if (inactiveEmploymentStatus) {
          inactiveLabels.employmentStatus = (
            <p className="text-red">
              {`${inactiveEmploymentStatus.name} is inactive!`}
            </p>
          );
        }
      }
      if (designationData && designationData.length !== 0 && designationData != 'isLoading') {
        const inactiveDesignation = findInactiveEntry(formValues.designation, designationData);
        if (inactiveDesignation) {
          inactiveLabels.designation = (
            <p className="text-red">
              {`${inactiveDesignation.name} is inactive!`}
            </p>
          );
        }
      }
      if (roles && Array.isArray(rolesResultData) && rolesResultData && rolesResultData.length !== 0 && rolesResultData != 'isLoading') {
        const selectedRoles = formValues.employeeRoles.map(role => ({
          roleId: role.roleId,
          roleName: role.roleName,
          active: true,
        }));

        const inactiveRoles = selectedRoles.filter(selectedRole => {
          const role = roles && Array.isArray(rolesResultData) && rolesResultData.find(r => r.id === selectedRole.roleId);
          if (role) {
            selectedRole.active = role.active;
            return !role.active;
          }
          return false;
        });

        if (inactiveRoles.length > 0) {
          inactiveLabels.employeeRoles = (
            <div>
              {inactiveRoles.map(role => (
                <p key={role.roleId} className="text-red">
                  {`${role.roleName} is inactive!`}
                </p>
              ))}
            </div>
          );
        }
      }

      setInactiveLabels(inactiveLabels);
    }
  }, [
    loadingData,
    location.id,
    location.empId,
    employee,
    genders,
    cities,
    designations,
    officeLocations,
    departments,
    teamsData,
    employmentStatuses,
    roles
  ]);
  const handleSelectChange = (e) => {
    const { name, value, label } = e.target;
    const inactive = inactiveLabels;
    if (name === "department") {

      if (departmentsData && departmentsData.length !== 0 && departmentsData != 'isLoading') {
        const inactiveDepartment = findInactiveEntry(value, departmentsData);
        if (inactiveDepartment) {
          inactive.department = (
            <p className="text-red">
              {`${inactiveDepartment.name} is inactive!`}
            </p>
          );
        } else {
          inactive.department = null;
        }
      }
    } else if (name === "team") {
      if (teams && Array.isArray(teamsData) && teamsData !== 'isLoading' && teamsData.length !== 0 && teamsData) {
        const inactiveTeam = findInactiveEntry(value, teamsData);
        if (inactiveTeam) {
          inactive.team = (
            <p className="text-red">
              {`${inactiveTeam.name} is inactive!`}
            </p>
          );
        } else {
          inactive.team = null;
        }
      }
    } else if (name === "employeeRoles") {
      if (roles && Array.isArray(rolesResultData) && rolesResultData && rolesResultData.length !== 0 && roles != 'isLoading') {
        const inactiveRoles = findInactiveEntry(value, roles);
        if (inactiveRoles) {
          inactive.employeeRoles = (
            <p className="text-red">
              {`${inactiveRoles.name} is inactive!`}
            </p>
          );
        } else {
          inactive.employeeRoles = null;
        }
      }
    } else if (name === "designation") {
      if (designationData && designationData.length !== 0 && designationData != 'isLoading') {
        const inactiveDesignation = findInactiveEntry(value, designationData);
        if (inactiveDesignation) {
          inactive.designation = (
            <p className="text-red">
              {`${inactiveDesignation.name} is inactive!`}
            </p>
          );
        } else {
          inactive.designation = null;
        }
      }
    } else if (name === "employmentStatus") {
      if (employmentStatusData && employmentStatusData.length !== 0 && employmentStatusData != 'isLoading') {
        const inactiveEmploymentStatus = findInactiveEntry(value, employmentStatusData);
        if (inactiveEmploymentStatus) {
          inactive.employmentStatus = (
            <p className="text-red">
              {`${inactiveEmploymentStatus.name} is inactive!`}
            </p>
          );
        } else {
          inactive.employmentStatus = null;
        }
      }
    }
    setInactiveLabels(inactive);
    if (name === "employeeRoles") {
      setFormValues({
        ...formValues,
        [name]: employee ? [{ roleId: value, employeeId: location.id }] : [{ roleId: value }],
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }

    setSelected({ ...selected, [name]: { value, label } });
  };


  const multiHanleChange = (e) => {
    setFormValues({
      ...formValues,
      ["employeeRoles"]: e.map((row, i) =>
        employee
          ? { roleId: row.value, employeeId: location.id }
          : { roleId: row.value }
      ),
    });
    setSelected({
      ...selected,
      ["employeeRoles"]: e.map((row, i) => ({
        value: row.value,
        label: row.label,
      })),
    });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleGoBack = () => {
    history.goBack();
  };
  const onRetry = () => {
    setRetryKey(!retryKey);
    setTryagain(false);
    setLoading(true);
  }
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  if (tryagain) {
    return (
      <div className="error-container">
        <h2>Something went wrong</h2>
        <p>Looks like we're experiencing an issue, please check your internet connection and try again.</p>
        <button className="retry-button" onClick={onRetry}>
          &#x21bb; Try Again
        </button>
      </div>
    );
  }

  return (
    <>
      {addEmployee && addEmployee === true && !location.id && <Redirect to="/admin/employees" />}
      {updateEmployee && updateEmployee === true && goBack && <Redirect to="/admin/employees" />}
      {!permissions.includes("CanAddEmployee") && <Redirect to="/admin" />}
      {!permissions.includes("CanUpdateEmployee") && <Redirect to="/admin" />}
      {!location.id && <Redirect to={"/admin/Addemployees"} />}
      <div>
        <div className="row">
          <div
            className="col-md-12"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <i onClick={() => history.push('/admin/employees')}>
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.5H7.83L13.42 5.91L12 4.5L4 12.5L12 20.5L13.41 19.09L7.83 13.5H20V11.5Z"
                  fill="#505157"
                />
              </svg>
            </i>{" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2 className="d-inline-block">
                {location.employee
                  ? `${name}`
                  : "Add Employee"}
              </h2>

            </div>
          </div>
        </div>
        <HrComponent />
        <br />

        <div className="row">
          <div className="col-12">
            <h2>Personal Details</h2>
          </div>
        </div>

        <HrComponent />
        <br />
        <div className="row">
          <div className="row mt-1">
            <div className="col-md-4">
              <TextField
                className="first_textfield"
                style={{ width: "100%" }}
                id="outlined-controlled"
                label="Employee Id"
                name="empId"
                value={formValues.empId}
                onChange={handleChange}
              />
              {formErrors.empId && <div>{formErrors.empId}</div>}
            </div>
            <div className="col-md-4">
              <TextField
                className="first_textfield"
                style={{ width: "100%" }}
                id="outlined-controlled"
                label="First Name *"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
              />
              {formErrors.firstName && <div>{formErrors.firstName}</div>}
            </div>
            <div className="col-md-4">
              <TextField
                className="first_textfield"
                style={{ width: "100%" }}
                id="outlined-controlled"
                label="Last Name *"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
              />
              {formErrors.lastName && <div>{formErrors.lastName}</div>}
            </div>

          </div>
          <div className="row mt-10">
            <div className="col-md-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Gender
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.gender}
                  onChange={handleSelectChange}
                  label="Gender"
                  name="gender"
                >
                  {getOptions(genders, "gender").map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formErrors.gender && <div>{formErrors.gender}</div>}
            </div>
            <div className="col-md-4">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="NIC"
                name="nic"
                value={formValues.nic}
                onChange={handleChange}
              />
              {formErrors.nic && <div>{formErrors.nic}</div>}
            </div>

            <div className="col-md-4">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Passport No"
                name="passport"
                value={formValues.passport}
                onChange={handleChange}
              />
              {formErrors.passport && <div>{formErrors.passport}</div>}
            </div>
          </div>

          <div className="row mt-10">
            <div className="col-md-4">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Personal Email *"
                name="personalEmail"
                value={formValues.personalEmail}
                onChange={handleChange}
                disabled={location.id ? true : false}
              />
              {formErrors.personalEmail && <div>{formErrors.personalEmail}</div>}
            </div>
            <div className="col-md-4">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Personal Contact 1 *"
                name="personalContact1"
                value={formValues.personalContact1}
                onChange={handleChange}
              />
              {formErrors.personalContact1 && <div>{formErrors.personalContact1}</div>}
            </div>

            <div className="col-md-4">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Personal Contact 2"
                name="personalContact2"
                value={formValues.personalContact2}
                onChange={handleChange}
              />
              {formErrors.personalContact2 && <div>{formErrors.personalContact2}</div>}
            </div>
          </div>

          <div className="row mt-10">
            <div className="col-md-6">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.city}
                  onChange={handleSelectChange}
                  name="city"
                  label="City"
                  MenuProps={{ PaperProps: { style: { maxHeight: 300, width: 250 } } }}
                >
                  {getOptions(cities && cities !== "isLoading" && cities.length !== 0 && cities[0].cities, 'city')
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formErrors.city && <div>{formErrors.city}</div>}
            </div>
            <div className="col-md-6">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Address *"
                name="address"
                value={formValues.address}
                onChange={handleChange}
              />
              {formErrors.address && <div>{formErrors.address}</div>}

            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <h2>Official Details</h2>
            </div>
          </div>

          <HrComponent />

          <div className="row mt-5">


            <div className="col-md-4 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Department *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.department}
                  name="department"
                  label="Department *"
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                >
                  {getOptions(departments && departments !== "isLoading" && departments.length !== 0 && departments.result, "department", true)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
              {formErrors.department && (
                <div>{formErrors.department}</div>
              )}
              {inactiveLabels.department && <div>{inactiveLabels.department}</div>}

            </div>

            <div className="col-md-4 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Designation *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.designation}
                  name="designation"
                  label="Designation *"
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                >
                  {getOptions(designations && designations !== "isLoading" && designations.length !== 0 && designations?.result?.[0]?.designations, "designation", true)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
              {formErrors.designation && (
                <div>{formErrors.designation}</div>
              )}
              {inactiveLabels.designation && <div>{inactiveLabels.designation}</div>}

            </div>

            <div className="col-md-4 mt-5">

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Role *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.employeeRoles.map(role => role.roleId)}
                  name="employeeRoles"
                  label="Role *"
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                >
                  {getOptions(roles && Array.isArray(rolesResultData) && rolesResultData, "employeeRoles", true)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}

                </Select>
              </FormControl>
              {formErrors.employeeRoles && <div>{formErrors.employeeRoles}</div>}
              {inactiveLabels.employeeRoles && <div>{inactiveLabels.employeeRoles}</div>}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-4 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Team *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.team}
                  name="team"
                  label="Team"
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                >
                  {getOptions(teamsData, "team", true)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formErrors.team && <div>{formErrors.team}</div>}
              {inactiveLabels.team && <div>{inactiveLabels.team}</div>}

            </div>

            <div className="col-md-4 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Reports To *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.reportsTo}
                  label="Reports To *"
                  name="reportsTo"
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                >
                  {getOptions(employees.result, "reportsTo")
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formErrors.reportsTo && <div>{formErrors.reportsTo}</div>}
            </div>
            <div className="col-md-4 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  VisitProfile
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.visitProfileId}
                  name="visitProfileId"
                  label="visitProfile "
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                >
                  {visitProfile && visitProfile !== "isLoading" && visitProfile.result && visitProfile.result.length !== 0 && visitProfile.result
                    .map(
                      (visitProfile) => (
                        <MenuItem key={visitProfile.visitProfileId} value={visitProfile.visitProfileId}>
                          {visitProfile.profileName}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>

            </div>

          </div>

          <div className="row mt-5">
            <div className="col-md-6 mt-5">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date Of Joining *"
                  valueType="date"
                  value={selected.dateOfJoining && selected.dateOfJoining.length != 0 && dayjs(selected.dateOfJoining)}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
              {formErrors.dateOfJoining && <div>{formErrors.dateOfJoining}</div>}
            </div>

            <div className="col-md-6 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Employment Status *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.employmentStatus}
                  name="employmentStatus"
                  label="Employment Status *"
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                >
                  {getOptions(employmentStatuses.result, "employmentStatus", true)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
              {formErrors.employmentStatus && <div>{formErrors.employmentStatus}</div>}
              {inactiveLabels.employmentStatus && <div>{inactiveLabels.employmentStatus}</div>}

            </div>
          </div>


          <div className="row mt-5">
            <div className="col-md-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Office Location *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.jobLocation}
                  name="jobLocation"
                  label="Office Location *"
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                >
                  {getOptions(officeLocations && officeLocations !== "isLoading" && officeLocations.length !== 0 && officeLocations[0].officeLocations, "jobLocation")

                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
              {formErrors.jobLocation && <div>{formErrors.jobLocation}</div>}
            </div>


          </div>

          <div className="col-md-12 mt-5">
            <div className="form-group" style={{ display: "flex", alignItems: "flex-start" }}>
              <input
                name="status"
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                type="checkbox"
                checked={formValues.status}
                className="mt-1 ml-1"
                onChange={handleChange}
              />

              <label
                style={{ paddingLeft: "10px", }}
                className="form-control-label"

              >
                Is this employee active?
              </label>
            </div>
          </div>





          <div className="row">
            <div
              className="col-lg-12 mt-10 mb-5"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <CancelButton
                onClick={handleGoBack}
              />
              <SaveButton
                onClick={addEmployeeHandle}
                isLoading={addEmployee === "isLoading" || updateEmployee === "isLoading"}
                text={location.employee ? "Update" : "Save"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEmployees;
